import React from "react";
import { Box, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface LogoProps {
  disabledLink?: boolean;
  variant?: "icon" | "full";
  sx?: any;
}

export default function Logo({
  disabledLink,
  variant = "icon",
  sx,
  ...props
}: LogoProps) {
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";
  const path2logo = isLightMode ? "/logo/icon.svg" : "/logo/icon_dark.svg";

  const logo = (
    <Box sx={{ width: 40, height: 40, cursor: "pointer", ...sx }}>
      {variant === "icon" ? (
        <img {...props} height="100%" width="auto" src={path2logo} alt="logo" />
      ) : (
        <img {...props} height="100%" width="auto" src={path2logo} alt="logo" />
      )}
    </Box>
  );

  return (
    <RouterLink to="/" style={{ textDecoration: "none" }}>
      {logo}
    </RouterLink>
  );
}
